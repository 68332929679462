<template>
  <!-- 库存详情 -->
  <div class="inventoryDetails page-info-content">
    <DetailsPage
      :detail-obj="inventoryDetailObj"
      :details-item-arr="detailsItemArr"
    />
    <Table
      class="table-conponents"
      :item-data="itemDataDetails"
      :list-data="itemDataList"
      :loading="loading"
    >
      <template #invWeight="{ row }">
        <span>{{ row.invWeight }}{{ row.unit || "吨" }}</span>
      </template>
      <template #avlbWeight="{ row }">
        <span>{{ row.avlbWeight }}{{ row.unit || "吨" }}</span>
      </template>
      <template #frozenWight="{ row }">
        <span>{{ row.frozenWight }}{{ row.unit || "吨" }}</span>
      </template>
    </Table>
    <h3 class="page-subtitle-shade">
      货位明细
    </h3>
    <Table :item-data="itemData" :list-data="listData" :loading="loading">
      <template #detailInvWeight="{ row }">
        <span>{{ row.detailInvWeight }}{{ row.unit || "吨" }}</span>
      </template>
    </Table>
  </div>
</template>

<script>
import DetailsPage from '@/components/DetailsPage'
import Table from '@/components/Table'
import { inventoryDetail } from '@/api/warehousingSystem'
export default {
  components: { DetailsPage, Table },
  data() {
    return {
      loading: false,
      pnid: '',
      stockDetail: {},
      inventoryDetailObj: {},
      detailsItemArr: [
        { label: '库存编码', value: 'inventoryNo' },
        { label: '仓库名称', value: 'warehouseName' },
        { label: '所属企业', value: 'custName' },
        { label: '第三方库存编码', value: 'trdInvNo' }
      ],
      itemDataDetails: [
        { label: '品名', prop: 'goodsName' },
        { label: '材质', prop: 'materials' },
        { label: '规格', prop: 'specs' },
        { label: '产地', prop: 'productions' },
        { label: '件重(吨)', prop: 'weights' },
        { label: '库存数量/单位', prop: 'invWeight' },
        { label: '可用数量/单位', prop: 'avlbWeight' },
        { label: '冻结数量/单位', prop: 'frozenWight' }
      ],
      itemDataList: [],
      listData: [],
      itemData: [
        { label: '库区编号', prop: 'goodsYardNo' },
        { label: '库区描述', prop: 'goodsYardRemark' },
        { label: '货位号', prop: 'detailLocationNo' },
        { label: '货位描述', prop: 'detailLocationDesc' },
        { label: '库存数量/单位', prop: 'detailInvWeight' }
      ],
      breadcrumbItem: [
        { path: '/', name: '首页' },
        { path: '/warehousingSystem/inventoryManagement', name: '库存管理' },
        { path: '', name: '详情' }
      ]
    }
  },
  mounted() {
    if (this.$route.query.id) {
      inventoryDetail({ id: this.$route.query.id }, (res) => {
        this.inventoryDetailObj = { ...res.data }
        this.listData = this.inventoryDetailObj.wmsInventorydetailRespList
        this.itemDataList.push({
          goodsName: res.data.goodsName,
          materials: res.data.materials,
          specs: res.data.specs,
          productions: res.data.productions,
          weights: res.data.weights,
          invWeight: res.data.invWeight,
          avlbWeight: res.data.avlbWeight,
          frozenWight: res.data.frozenWight,
          unit: res.data.unit
        })
        this.pnid = this.inventoryDetailObj.goodsWeightsId
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.inventoryDetails {
  display: block;
  .table-conponents {
    margin-bottom: 32px;
  }
  .buttonCombination {
    margin-top: 32px;
    text-align: center;
  }
  /deep/.el-descriptions {
    padding: 0 !important;
  }

  .el-descriptions:last-of-type {
    padding: 12px 32px 0 !important;
  }

  .specificItem .el-descriptions {
    padding: 0 32px !important;
  }

  .buttonCombination .button {
    margin-right: 32px;
  }

  .tableBox .el-input,
  .tableBox .el-input .el-input__inner {
    width: 140px;
    border: 0;
  }

  .el-descriptions-item__content input {
    /* width: 100px; */
    height: 32px;
    border: 1px solid #ededed;
    outline: 0;
    transform: translateY(-10px);
    text-indent: 6px;
  }

  .el-descriptions {
    padding: 40px 32px;
  }

  .el-descriptions:last-of-type {
    padding: 0 32px;
  }

  .el-descriptions-row td {
    padding-bottom: 28px !important;
  }

  .tableBox {
    margin: auto;
    text-align: center;
    width: calc(100% - 128px);
    border-collapse: collapse;
  }

  .tableBox td {
    height: 40px;
    border: 1px solid #ededed;
  }

  .el-descriptions .el-descriptions__body .link {
    color: rgb(64, 158, 255);
    text-decoration: underline;
    cursor: pointer;
  }

  .el-descriptions .el-descriptions__body {
    padding: 0 32px;
  }
}
</style>
